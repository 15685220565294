<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
      <bar-select-date v-if="getPageDashboard" @onYearMonth="onYearMonth" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <cart-dropdown />
      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-flex flex-column user-nav profile-general">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }} {{ user.last_name }}
            </p>
            <span class="user-status">{{ user.roles[0].name }}</span>
          </div>
          <div
            v-if="user && user.name"
            class="border border-primary initial-badge"
          >
            {{ shortName }}
          </div>

          <div v-else class="border border-primary initial-badge">N N</div>
        </template>

        <b-dropdown-item
          :to="{ name: 'profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="onLogout"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Salir</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import NotificationDropdown from "@core/layouts/components/app-navbar/components/NotificationDropdown";
import CartDropdown from "@core/layouts/components/app-navbar/components/CartDropdown";
import UserService from "@/services/UserService";
import BarSelectDate from "@/views/pages/dashboard/components/BarSelectDate.vue";
import moment from "moment";
import { mapGetters, mapMutations } from 'vuex';
import { isNumber } from '@vueuse/shared';

export default {
  components: {
    BLink,
    BNavbarNav,
    DarkToggler,
    NotificationDropdown,
    CartDropdown,
    BarSelectDate,
  },
  computed: {
    ...mapGetters('dashboard', ['getPageDashboard']),
    shortName: function () {
      if (this.user) {
        const arr = [this.user.name[0]];
        if (this.user.last_name) {
          arr.push(this.user.last_name[0]);
        } else {
          arr.push(this.user.name[1]);
        }
        return arr.join("").toUpperCase();
      }
      return "??";
    },
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: UserService.user(),
      month: moment().month(),
      year: moment().year(),
    };
  },
  methods: {
    ...mapMutations('dashboard', ['setYear', 'setMonth', 'setClient']),
    /**
     *
     */
    onYearMonth({ year, month, client }) {
      let newClient = isNumber(client) ? parseInt(client) : null;
      this.setYear(parseInt(year));
      this.setMonth(parseInt(month));
      this.setClient(newClient);
    },
    /**
     *
     */
    viewProfile() {
      this.$router.push("/dashboard/settings/profile");
    },
    /**
     *
     */
    onLogout() {
      UserService.logout()
        .then(() => {
          localStorage.clear();
          this.$router.push("/");
        })
        .catch(() => {
          localStorage.clear();
          this.$router.push("/");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.initial-badge {
  border-radius: 50%;
  padding: 7px !important;
  padding-top: 12px !important;
  border-style: solid !important;
  border-width: 5px !important;
  background-color: #f68d1e;
  color: #ffffff;
  font-weight: 900;
  font-size: 15px;
  height: 50px;
  margin-left: 10px;
}

.initial-badge:hover {
  box-shadow: 0 4px 8px 0 rgba(246, 141, 30, 0.2),
    0 6px 20px 0 rgba(246, 141, 30, 0.19);
  cursor: pointer;
}

.profile-general:hover {
  cursor: pointer;
}

.logout:hover {
  cursor: pointer;
}

.logout svg:hover {
  width: 25px !important;
  height: 25px !important;
  color: #f68d1e;
}
</style>
