<template>
  <div class="notificaciones" style="float: right" v-if="noti">
    <b-dropdown
      variant="link"
      toggle-class="text-decoration-none"
      menuClass="notification-menu"
      no-caret
    >
      <template v-slot:button-content>
        <feather-icon
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="20"
        />
        <b-badge pill variant="danger" v-if="noti.totalUnRead > 0">
          {{ noti.totalUnRead }}
        </b-badge>
      </template>
      <b-dropdown-group class="pending-by-read" header="Pendiente por leer">
        <vue-perfect-scrollbar
          v-once
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          tagname="li"
        >
          <li v-if="noti.notifications.length === 0">
            <div class="text-center">
              <div
                class="d-flex justify-content-center"
                style="font-size: 26px; margin-top: 100px"
              >
                <feather-icon icon="ArchiveIcon" size="25" class="mb-2" />
              </div>
              <span>No tienes notificaciones por leer</span>
            </div>
          </li>
          <template v-else>
            <li
              v-bind:key="item.id"
              v-for="item in noti.notifications"
              class="dropdown-item item"
              @click="onViewNotification"
            >
              <div class="text font-weight-bold text-break">
                <feather-icon class="text-body" icon="BookmarkIcon" size="20" />
                <span style="white-space: initial">
                  {{ item.data.text || item.data.title }}
                </span>
              </div>
            </li>
          </template>
        </vue-perfect-scrollbar>
      </b-dropdown-group>
      <!-- <b-dropdown-divider></b-dropdown-divider> -->
      <b-dropdown-group class="history">
        <li class="d-flex justify-content-between p-1">
          <a href="#" @click="viewAll" class="text-primary font-weight-bold">
            Marca todo como leido
          </a>
          <router-link
            to="/dashboard/notifications"
            class="text-primary pull-right font-weight-bold"
          >
            Historial
          </router-link>
        </li>
      </b-dropdown-group>
    </b-dropdown>
  </div>
</template>

<script>
// import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
import UserService from "@/services/UserService";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "notification-component",
  components: { VuePerfectScrollbar },
  data: () => {
    return {
      noti: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  methods: {
    viewAll() {
      UserService.readAllNotifications().then(() => {
        this.noti.notifications = [];
        this.noti.totalUnRead = 0;
      });
    },
    onViewNotification() {
      this.$router.push("/dashboard/notifications");
    },
  },
  mounted() {
    UserService.notifications().then((response) => {
      const { data } = response.data;
      this.noti = data;
    });
  },
};
</script>

<style lang="scss">
.pending-by-read {
  height: 300px;
  max-height: 600px;
  overflow-y: hidden;
  header {
    margin-bottom: 10px;
    color: #ef7636;
  }
  .text {
    margin: 0px;
  }
  .item {
    color: #73818f;
    &:hover {
      background: #ef7636;
      color: #fff !important;
      font-weight: bold;

      svg {
        color: #ffffff !important;
      }
    }
  }
}
.notification-menu {
  width: 360px !important;
  transform: translate3d(-260px, 0px, 0px) !important;
}
</style>
