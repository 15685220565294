<template>
  <!-- <b-card class="p-1 mb-0"> -->
    <b-form class="mb-0 w-100" inline>
      <b-form-select
        class="mb-0 mx-1 select-date-custom"
        v-model="year"
        @input="lodaData"
        :options="years"
      ></b-form-select>
      <b-form-select class="mb-0 mx-1 select-date-custom" v-model="month" @input="lodaData">
        <option value="0">Enero</option>
        <option value="1">Febrero</option>
        <option value="2">Marzo</option>
        <option value="3">Abril</option>
        <option value="4">Mayo</option>
        <option value="5">Junio</option>
        <option value="6">Julio</option>
        <option value="7">Agosto</option>
        <option value="8">Septiembre</option>
        <option value="9">Octubre</option>
        <option value="10">Noviembre</option>
        <option value="11">Diciembre</option>
      </b-form-select>
      <SelectCustomer v-if="isAdmin" @select="onSelect" :dashboard="true"/>
    </b-form>
  <!-- </b-card> -->
</template>

<script>
import moment from "moment";
import SelectCustomer from "@/views/pages/operations/components/SelectCustomer";
import UserService from '@/services/UserService';

export default {
  name: "BarSelectDate",
  components: {
    SelectCustomer,
  },
  data() {
    return {
      selected: null,
      client: null,
      isAdmin: UserService.isAdmin(),
      month: moment().month(),
      year: moment().year(),
      years: Array(8)
        .fill(2019)
        .map((v, i) => {
          return { value: v + i, text: v + i };
        })
    };
  },
  methods: {
    lodaData() {
      const { year, month, client } = this;
      this.$emit("onYearMonth", { year, month, client });
    },
    onSelect(item) {
      this.client = item ? item.id : null;
      this.lodaData();
    },
  }
};
</script> 
