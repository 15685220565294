<template>
  <!-- <b-nav-item-dropdown
    class="dropdown-cart mr-10"
    menu-class="dropdown-menu-media"
    right
  > -->
  <div v-if="!isAdmin">
    <template>
      <template>
        <div class="title-debt font-weight-bold">
          Deuda
          {{
            (user.amount_debt.cost +
              user.amount_debt.transactionNotCostSend +
              (user.amount_debt_extra
                ? user.amount_debt_extra.TransactionExtraCharge
                : 0))
              | currency
          }}
        </div>
      </template>
    </template>
  </div>

  <!-- Header -->
  <!-- <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Detalle
        </h4>
      </div>
    </li> -->

  <!-- Cart Items -->
  <!-- <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
        <template style="float: right">
          <b-dropdown-item>
            <div>Envío {{ user.amount_debt.amount.costSend | currency }}</div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div>
              Retiro
              {{ user.amount_debt.amount.costOperation | currency }}
            </div>
          </b-dropdown-item>
        </template>
    </vue-perfect-scrollbar> -->
  <!-- </b-nav-item-dropdown> -->
</template>

<script>
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import UserService from "@/services/UserService";

export default {
  name: "CartDropdown",
  components: {
    // VuePerfectScrollbar
  },
  data: () => {
    return {
      isAdmin: UserService.isAdmin(),
      user: {
        amount_debt: {
          amount: 0,
        },
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  methods: {
    getUser() {
      return UserService.user();
    },
    loadDebt() {
      if (this.getUser().amount_debt) {
        if (this.getUser().amount_debt != null)
          this.user.amount_debt.amount = this.getUser().amount_debt;
      }
    },
  },
  mounted() {
    UserService.me()
      .then((response) => {
        this.user = response.data.data;
        UserService.setUser(response.data.data);
        this.loadDebt();
      })
      .catch((error) => {
        console.error(error);
        this.loadDebt();
      });
  },
};
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
