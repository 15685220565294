export default {
	customer: {
		items: [
			{
				title: 'Dashboard',
				route: 'dashboard',
				icon: 'PieChartIcon',
			},
			{
				title: 'Operaciones',
				route: 'operations',
				icon: 'ListIcon',
			},
			{
				title: 'Balance',
				route: 'balances',
				icon: 'TrendingUpIcon'
			},
		]
	},
	reseller: {
		items: [
			{
				title: 'Operaciones',
				route: 'operations',
				icon: 'ListIcon',
			},
			{
				title: 'Tiendas',
				route: 'customers',
				icon: 'UsersIcon'
			}, 
			{
				title: 'Puntos',
				route: 'points',
				icon: 'DiscIcon'
			},
		]
	},
	admin: {
		items: [
			{
				title: 'Dashboard',
				route: 'dashboard',
				icon: 'PieChartIcon',
			},
			{
				title: 'Operaciones',
				route: 'operations',
				icon: 'ListIcon',
			},
			{
				title: 'Balance',
				route: 'balances',
				icon: 'TrendingUpIcon'
			},
			{
				title: 'Tiendas',
				route: 'customers',
				icon: 'UsersIcon'
			},
			{
				title: 'Puntos',
				route: 'points',
				icon: 'DiscIcon'
			},
			{
				title: 'Precios',
				route: 'prices',
				icon: 'DollarSignIcon'
			},
			{
				title: 'Localidades',
				route: 'locations',
				icon: 'GlobeIcon'
			},
			{
				title: 'Servicios',
				route: 'services',
				icon: 'RepeatIcon'
			},
			{
				title: 'Configuración',
				icon: 'SettingsIcon',
				children: [
					{
						title: 'Estados',
						route: 'state',
						icon: 'ColumnsIcon',
					},
					{
						title: 'Usuarios',
						route: 'usuarios',
						icon: 'UserCheckIcon'
					},
					{
						title: 'Notificaciones Tienda',
						route: 'storeNotification',
						icon: 'RadioIcon'
					},
					{
						title: 'Cambio y devolución',
						route: 'settingExchangeAndRefund',
						icon: 'PackageIcon'
					},
					{
						title: 'Seguro',
						route: 'settingPercentageDeclared',
						icon: 'LockIcon'
					},
					{
						title: 'Codigos Postales',
						route: 'SettingPostalCode',
						icon: 'MapPinIcon'
					},
				]
			},
		]
	},
	staff: {
		items: [
			{
				title: 'Operaciones',
				route: 'operations',
				icon: 'ListIcon',
			},
			{
				title: 'Tiendas',
				route: 'customers',
				icon: 'UsersIcon'
			}, 
			{
				title: 'Puntos',
				route: 'points',
				icon: 'DiscIcon'
			},
			{
				title: 'Precios',
				route: 'prices',
				icon: 'DollarSignIcon'
			},
			{
				title: 'Balance',
				route: 'balances',
				icon: 'TrendingUpIcon'
			},
			{
				title: 'Usuarios',
				route: 'usuarios',
				icon: 'UserCheckIcon'
			},
			{
				title: 'Servicios',
				route: 'services',
				icon: 'RepeatIcon'
			},
		]
	}
}


